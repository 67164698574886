import { CircularProgress, Divider, Tooltip, Typography } from '@material-ui/core'
import {
  Add as AddIcon,
  ArrowForward as ArrowForwardIcon,
  CloudDownload as CloudDownloadIcon,
} from '@material-ui/icons'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CheckboxField from '../../../shared/components/CheckboxField'
import { fetchSecure, sCredentials } from '../../../shared/utils/auth'
import { getApiUrl } from '../../../utils/auth'
import DisabledInformation from './DisabledInformation'
import {
  AddUserButton,
  CheckboxContainer,
  EnterPracticeButton,
  ExportDataButton,
  PracticeInfo,
  PracticeInfoTitle,
  PracticeLogo,
  PraxisCardContainer,
  PraxisCardDivider,
} from './StyledPraxisCard'

const PraxisCard = ({
  image,
  title,
  disabled,
  isWhitelisted,
  nameHash,
  inhaber,
  loadPraxisstammdatenComplete,
  noOfPractices,
  className,
  isAdmin = false,
  totalPatientsCreated,
  setTotalPatientsCreated,
  onAddUser = () => {},
  onRequestDataExport = () => {},
  readOnly,
}) => {
  const [openInNewTab, setOpenInNewTab] = useState<boolean>(noOfPractices > 1)
  const [numberOfPatients, setNumberOfPatients] = useState(0)

  const navigate = useNavigate()

  const handleCheckboxChange = (newValue) => {
    window.localStorage.setItem('openPraxisInNewTab', `${newValue}`)
    setOpenInNewTab(newValue)
  }
  const handleButtonClick = () => {
    const newWindow = window.open(`/${nameHash}/calendar`, openInNewTab ? '_blank' : '_self')
    newWindow?.focus()
  }

  useEffect(() => {
    if ('openPraxisInNewTab' in window.localStorage) {
      setOpenInNewTab(window.localStorage.openPraxisInNewTab === 'true')
    }
  }, [nameHash])

  const isDisabled = disabled || !isWhitelisted

  useEffect(() => {
    const fetchPatients = async () => {
      const credentials = sCredentials()
      const apiUrl = getApiUrl(nameHash)

      const response = await fetchSecure(
        `${apiUrl}/patienten/amount`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          credentials: 'include', // send credentials like cookies & basic auth
        },
        credentials,
      )

      const data = await response.json()
      return data.length
    }

    const calculatePatients = async () => {
      const patients = await fetchPatients()
      setNumberOfPatients(patients)
      if (!isDisabled) {
        setTotalPatientsCreated((prevValue) => prevValue + patients)
      }
    }
    calculatePatients()
  }, [])

  if (loadPraxisstammdatenComplete && !isDisabled) {
    return (
      <PraxisCardContainer className={className} square>
        <PracticeLogo src={image} alt="Praxislogo" />
        <PracticeInfo>
          <Tooltip title={title} placement="top">
            <PracticeInfoTitle variant="h6">
              {(title || '').length > 52 ? `${title.substring(0, 52)}...` : title}
            </PracticeInfoTitle>
          </Tooltip>
          <Typography variant="body2">{`Inhaber: ${inhaber}`}</Typography>
          <Divider style={{ margin: '5px 0' }} />
          <Typography variant="body2">
            Anzahl Patient:innen: <b>{numberOfPatients}</b>
          </Typography>
          {/* <Typography variant="body2">
            Anzahl gesendeter SMS: <b>140</b>
          </Typography> */}
        </PracticeInfo>
        {noOfPractices > 1 && (
          <CheckboxContainer className="checkboxContainer">
            <PraxisCardDivider />
            <CheckboxField
              className="checkbox"
              onChange={handleCheckboxChange}
              checked={openInNewTab}
              label="in neuem Tab öffnen"
            />
          </CheckboxContainer>
        )}
        <EnterPracticeButton
          color="secondary"
          variant="contained"
          startIcon={<ArrowForwardIcon />}
          onClick={handleButtonClick}
        >
          Praxis betreten
        </EnterPracticeButton>
        {isAdmin && (
          <Tooltip title="Praxisdaten exportieren" placement="bottom">
            <ExportDataButton className="exportDataButton" size="medium" onClick={onRequestDataExport}>
              <CloudDownloadIcon fontSize="large" />
            </ExportDataButton>
          </Tooltip>
        )}
        {isAdmin && !readOnly && (
          <AddUserButton className="addUserButton" size="medium" onClick={onAddUser}>
            <AddIcon fontSize="large" />
          </AddUserButton>
        )}
      </PraxisCardContainer>
    )
  }

  if (isDisabled) {
    return (
      <PraxisCardContainer square className={className}>
        <PracticeInfo>
          <Tooltip title={title} placement="top">
            <PracticeInfoTitle variant="h6">
              {(title || '').length > 52 ? `${title.substring(0, 52)}...` : title}
            </PracticeInfoTitle>
          </Tooltip>
          <Typography variant="body2">{`Inhaber: ${inhaber}`}</Typography>
          <DisabledInformation />
        </PracticeInfo>
      </PraxisCardContainer>
    )
  }

  return (
    <PraxisCardContainer>
      <CircularProgress />
    </PraxisCardContainer>
  )
}

export default PraxisCard
