import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'
import type { RequestConfig } from './interface'

export const requestDataExport = ({ instanceHash }): RequestConfig => {
  return {
    types: [types.REQUEST_DATA_EXPORT, types.REQUEST_DATA_EXPORT_SUCCESS, types.REQUEST_DATA_EXPORT_FAILURE],
    requiresAuth: true,
    shouldCallAPI: (): boolean => true,
    authServer: true,
    callAPI: ({ authUrl, auth0Credentials }): void => {
      const URL = `${authUrl}/request-export`

      return fetchSecure(
        URL,
        {
          method: 'POST',
          credentials: 'include', // send credentials like cookies & basic auth
          body: JSON.stringify({ instanceHash }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
        auth0Credentials,
      )
    },
    payload: { instanceHash },
  }
}
