import * as types from '../constants/actionTypes'
import { fetchSecure } from '../shared/utils/auth'

export function getCallCount() {
  return {
    types: [types.GET_CALL_COUNT, types.GET_CALL_COUNT_SUCCESS, types.GET_CALL_COUNT_FAILURE],
    requiresAuth: true,
    // shouldCallAPI: ( state ) => state.ids.anamnesiss.length <= 0,
    shouldCallAPI: (): boolean => true,
    callAPI: ({ apiUrl, auth0Credentials }): void => {
      const URL = `${apiUrl}/call-count`

      return fetchSecure(
        URL,
        {
          credentials: 'include', // send credentials like cookies & basic auth
        },
        auth0Credentials,
      )
    },
  }
}
