import { Button, Card, Divider, Fab, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const PraxisCardContainer = styled(Card)`
  position: relative;
  overflow: visible;
  width: 300px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`};
  transition: background-color 0.5s ease 0s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    .checkboxContainer {
      visibility: visible;
    }

    .addUserButton {
      display: flex;
    }

    .exportDataButton {
      display: flex;
    }
  }
`

export const PracticeLogo = styled.img`
  max-height: 40px;
  max-width: 260px;
  align-self: flex-start;
`

export const PracticeInfo = styled.div`
  margin-top: 10px;
  word-break: break-word;
`

export const PracticeInfoTitle = styled(Typography)`
  cursor: pointer;
  max-height: 64px;
  overflow: hidden;
`

export const CheckboxContainer = styled.div`
  visibility: hidden;

  .MuiCheckbox-root {
    padding: 4px;
  }

  .checkbox {
    margin-top: 4px;
  }
`

export const EnterPracticeButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 5px;
`

export const PraxisCardDivider = styled(Divider)`
  width: 270px;
  margin-top: 8px;
`

export const AddUserButton = styled(Fab)`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: #fff;
  display: none;
`

export const ExportDataButton = styled(Fab)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: #fff;
  display: none;
`

export const StyledPraxisCard = styled(Card)`
  ${({ theme }) => `
    .floatingButton {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(50%, 50%);
      background-color: ${theme.palette.secondary.main};
      color: #fff;
    }
  `}
`

// .praxisInfo {
//   margin-top: 10px;
//   word-break: break-all;
// }
