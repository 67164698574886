import { Button, FormControlLabel, Link as MuiLink, Switch, Typography } from '@material-ui/core'
import { Eco as EcoIcon } from '@material-ui/icons'
import { differenceInMinutes, format } from 'date-fns'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { reduxForm } from 'redux-form'
import * as hvoActions from '../../actions/heilmittelverordnungen'
import CurrencyField from '../../components/CurrencyField'
import NewHVOForm from '../../components/HVOForm/NewHVOForm'
import PatientText from '../../components/PatientText'
import { GERMAN_DATE_SHORT_YEAR_FNS } from '../../constants/dateFormats'
import DatesListView from '../../containers/DatesListView'
import FileUploadWithLightbox from '../../containers/FileUploadWithLightbox'
import { praxisstammdatenSelector } from '../../selectors/selectors'
import IconHeading from '../../shared/components/IconHeading'
import InputField from '../../shared/components/InputField/InputField'
import { sApiServer } from '../../shared/utils/auth'
import { plan } from '../../shared/utils/constants'
import { sCurrentUser } from '../../shared/utils/users'
import { calcHVOPrices } from '../../utils/calcHVOPrices'
import { getDateBefore } from '../../utils/dates'
import { isReadOnly } from '../../utils/helpers'
import { StyledViewHVO } from './StyledViewHVO'

export const fields = ['id', 'zuzahlungErhalten', 'behandlungAbgebrochen', 'abgerechnet', 'dateien']

interface Props {
  actions: any
  customers: any
  heilmittelverordnungen: any
  praxisstammdaten: any
  bundeslaender: any
  krankenkassen: any
  leistungenKK: any
  termine: any
  verguetungKK: Array<any>
  state: any
  aerzte: any
  currentUser: any
  dialogActions: any

  fields: any
  initializeForm: any
  handleSubmit: any
  pristine: any

  currentServer: any
}

const ViewHVO: FC<Props> = ({
  actions,
  customers,
  heilmittelverordnungen,
  praxisstammdaten,
  bundeslaender,
  krankenkassen,
  leistungenKK,
  termine,
  verguetungKK,
  state,
  aerzte,
  currentUser,
  dialogActions,
  fields,
  fields: { zuzahlungErhalten, behandlungAbgebrochen, abgerechnet, dateien },

  initializeForm,
  handleSubmit,
  pristine,
  currentServer,
  practicePlanId,
}) => {
  const [hvoLoaded, setHvoLoaded] = useState(false)
  const { heilmittelverordnung, serverHash } = useParams()

  useEffect(() => {
    ;(async () => {
      await actions.heilmittelverordnungen.loadHeilmittelverordnung(heilmittelverordnung, true)
      setHvoLoaded(true)
    })()
  }, [actions, heilmittelverordnung])

  useEffect(() => {
    const { id } = fields

    if (!id.value && heilmittelverordnungen[heilmittelverordnung]) {
      const hvo = heilmittelverordnungen[heilmittelverordnung]
      initializeForm({
        id: hvo.id,
        zuzahlungErhalten: hvo.zuzahlungErhalten === null ? false : hvo.zuzahlungErhalten,
        behandlungAbgebrochen: hvo.behandlungAbgebrochen === null ? false : hvo.behandlungAbgebrochen,
        abgerechnet: hvo.abgerechnet === null ? false : hvo.abgerechnet,
        dateien: hvo.dateien || [],
      })
    }
  }, [fields, heilmittelverordnung, heilmittelverordnungen, initializeForm])

  const _saveForm = async (data) => {
    const hvo = heilmittelverordnungen[heilmittelverordnung]
    if (!hvo || !hvo.id) {
      return
    }
    await actions.heilmittelverordnungen.updateHeilmittelverordnung({
      ...hvo,
      ...data,
    })

    initializeForm(data)
  }

  const _handleInputChange = (fieldName) => (event) => {
    fields[fieldName].onChange(event)
    // setTimeout is required here because the state does not contain the changed form values ​​until the next tick.
    setTimeout(() => handleSubmit(_saveForm)())
  }

  const _handleAbrechnenClick = useCallback(
    async (additionalData) => {
      const hvo = heilmittelverordnungen[heilmittelverordnung]

      if (!hvo || !hvo.id) {
        return
      }
      const dataToSubmit = Object.assign(
        {},
        {
          ...hvo,
          positionsNummerHausbesuch: additionalData.hausbesuchPositionsnummer,
        },
      )

      try {
        const response = await actions.heilmittelverordnungen.submitHvo(dataToSubmit)

        if (response) {
          console.log('response erhalten!')
          await actions.heilmittelverordnungen.loadHeilmittelverordnung(heilmittelverordnung, true)
        }
      } catch (err) {
        console.log('err on hvo submit: ', err)
      }
    },
    [
      heilmittelverordnungen,
      heilmittelverordnung,
      actions.heilmittelverordnungen.submitHvo,
      actions.heilmittelverordnungen.loadHeilmittelverordnung,
    ],
  )

  // ---
  const [patient, setPatient] = useState<any>(null)
  useEffect(() => {
    let newPatient: any = null
    if (customers && heilmittelverordnungen && heilmittelverordnung && heilmittelverordnungen[heilmittelverordnung]) {
      newPatient = customers[heilmittelverordnungen[heilmittelverordnung].patient]
    }
    setPatient(newPatient)
  }, [customers, heilmittelverordnungen, heilmittelverordnung])
  // ---
  const [hvo, setHVO] = useState<any>(null)
  useEffect(() => {
    let newHVO: any = null
    if (heilmittelverordnungen && heilmittelverordnung) {
      newHVO = heilmittelverordnungen[heilmittelverordnung]
    }
    setHVO(newHVO)
  }, [heilmittelverordnungen, heilmittelverordnung])
  // ---
  const [arzt, setArzt] = useState<any>(null)
  useEffect(() => {
    let newArzt: any = null
    if (hvo && aerzte) {
      newArzt = aerzte[hvo.arzt]
    }
    setArzt(newArzt)
  }, [hvo, aerzte])
  // ---
  const [hvoComplete, setHvoComplete] = useState<boolean>(false)
  useEffect(() => {
    let newHvoComplete: boolean = false

    if (termine && hvo) {
      const terminArray: any[] = []
      for (const t in termine) {
        terminArray.push(termine[t])
      }
      const now = new Date()

      const nichtAbgesagteUndVergangeneTermine = hvo.nagelkorrektur
        ? terminArray.filter(
            (termin) =>
              (termin.absagegrund === null || termin.absagegrund === '') &&
              termin.heilmittelverordnung === hvo.id &&
              termin.beginn <= now &&
              termin.leistungen &&
              termin.leistungen.some(
                (l) => leistungenKK[l.positionsnummer] && leistungenKK[l.positionsnummer].prescribedService,
              ),
          )
        : terminArray.filter(
            (termin) =>
              (termin.absagegrund === null || termin.absagegrund === '') &&
              termin.heilmittelverordnung === hvo.id &&
              termin.beginn <= now,
          )

      newHvoComplete = hvo.nagelkorrektur
        ? !!(
            (nichtAbgesagteUndVergangeneTermine.length > 0 &&
              hvo.verordnungsmenge >= nichtAbgesagteUndVergangeneTermine.length) ||
            (hvo.behandlungAbgebrochen && nichtAbgesagteUndVergangeneTermine.length > 0)
          )
        : !!(
            hvo.verordnungsmenge === nichtAbgesagteUndVergangeneTermine.length ||
            (hvo.behandlungAbgebrochen && nichtAbgesagteUndVergangeneTermine.length > 0)
          )
    }
    setHvoComplete(newHvoComplete)
  }, [termine, hvo])

  const [hvoDaten, setHvoDaten] = useState<any>(null)
  useEffect(() => {
    let newHvoDaten: any = null
    if (state && hvo) {
      newHvoDaten = calcHVOPrices(
        {
          ...hvo,
          behandlungAbgebrochen: behandlungAbgebrochen.value,
          abgerechnet: abgerechnet.value,
        },
        state,
      )
    }
    console.log('newHvoDaten: ', newHvoDaten)
    setHvoDaten(newHvoDaten)
  }, [state, hvo, behandlungAbgebrochen, abgerechnet])
  // ---
  const isHvoAbrechenbar = useMemo(() => {
    if (!hvo) return false

    const result: Record<string, boolean | string[]> = {
      prescription: true,
      patient: true,
      doctor: true,
      masterData: true,
    }

    const patient = customers?.[hvo.patient]
    const doctor = hvo.arzt ? aerzte?.[hvo.arzt] : {}
    const masterData = praxisstammdaten

    const prescriptionResult = {
      Straße: !!hvo.strasse,
      'Haus-Nr.': !!hvo.hausnummer,
      PLZ: !!hvo.postleitzahl,
      Ort: !!hvo.ort,
      Ausstellungsdatum: !!hvo.ausstellungsdatum,
      Arzt: !!hvo.arzt,
      'IK-Nr. KK': !!hvo.ikNummerKK,
      Krankenkasse: !!hvo.krankenkasse,
      'Versicherten-Nr.': !!hvo.versichertennummer,
      'Versicherten-Status': !!hvo.versichertenstatus,
      'ICD10-Code': !!hvo.icd10Code,
    }

    const patientResult = {
      Geschlecht: !!patient.geschlecht,
      Nachname: !!patient.nachname,
      Vorname: !!patient.vorname,
      Geburtsdatum: !!patient.geburtsdatum,
    }

    const doctorResult = {
      LANR: !!doctor.lanr,
      BSNR: !!doctor.bsnr,
      Nachname: !!doctor.nachname,
    }

    const masterDataResult = {
      'IK-Nr.': !!masterData.institutionskennzeichen,
    }

    for (const key in prescriptionResult) {
      if (prescriptionResult[key] === false) {
        if (!Array.isArray(result.prescription)) {
          result.prescription = []
        }

        result.prescription.push(key)
      }
    }

    for (const key in patientResult) {
      if (patientResult[key] === false) {
        if (!Array.isArray(result.patient)) {
          result.patient = []
        }

        result.patient.push(key)
      }
    }

    for (const key in doctorResult) {
      if (doctorResult[key] === false) {
        if (!Array.isArray(result.doctor)) {
          result.doctor = []
        }

        result.doctor.push(key)
      }
    }

    for (const key in masterDataResult) {
      if (masterDataResult[key] === false) {
        if (!Array.isArray(result.masterData)) {
          result.masterData = []
        }

        result.masterData.push(key)
      }
    }

    return result
  }, [aerzte, customers, praxisstammdaten, hvo])
  // ---
  const [begruendungenFrequenzueberschreitung, setBegruendungenFrequenzueberschreitung] = useState<string[] | null>(
    null,
  )
  useEffect(() => {
    let newBegruendungenFrequenzueberschreitung: string[] | null = null
    if (hvoDaten) {
      let value = hvoDaten.appointments
        .filter((termin) => termin.begruendungFrequenzueberschreitung !== null)
        .sort((date1, date2) => {
          return date2.beginn - date1.beginn
        })
        .map(
          (termin) =>
            `${format(termin.beginn, GERMAN_DATE_SHORT_YEAR_FNS)}: ${termin.begruendungFrequenzueberschreitung}`,
        )
      value = value.length > 0 ? value : ['keine']
      newBegruendungenFrequenzueberschreitung = value
    }
    setBegruendungenFrequenzueberschreitung(newBegruendungenFrequenzueberschreitung)
  }, [hvoDaten])
  // ---
  const [dateBefore, setDateBefore] = useState<any>(null)
  useEffect(() => {
    let newDateBefore: any = null
    if (hvoDaten) {
      const today = new Date()
      newDateBefore = getDateBefore(today, hvoDaten.appointments)
    }
    setDateBefore(newDateBefore)
  }, [hvoDaten])
  // ---

  const [isHVOFormChanged, setIsHVOFormChanged] = useState<boolean>(false)

  useEffect(() => {
    setIsHVOFormChanged(!isHVOFormChanged)

    //reinit form when it's changing
    if (heilmittelverordnungen[heilmittelverordnung]) {
      const hvo = heilmittelverordnungen[heilmittelverordnung]
      initializeForm({
        id: hvo.id,
        zuzahlungErhalten: hvo.zuzahlungErhalten === null ? false : hvo.zuzahlungErhalten,
        behandlungAbgebrochen: hvo.behandlungAbgebrochen === null ? false : hvo.behandlungAbgebrochen,
        abgerechnet: hvo.abgerechnet === null ? false : hvo.abgerechnet,
        dateien: hvo.dateien || [],
      })
    }
  }, [heilmittelverordnung])

  const hvoInterfaceIsIncludedInPlan = useMemo(() => {
    if (!practicePlanId) return false

    const planDataForId = plan.find((p) => p.id === practicePlanId)

    if (!planDataForId) return false

    return planDataForId.kkInterfaceIncluded
  }, [practicePlanId])

  if (!hvoLoaded || !praxisstammdaten || !heilmittelverordnungen[heilmittelverordnung]) {
    return null
  }

  return (
    <StyledViewHVO>
      <IconHeading
        icon={<EcoIcon />} // ion-leaf
        text={
          <span>
            Heilmittelverordnung&nbsp;
            {!isReadOnly() && (
              <>
                (<Link to={`/${serverHash}/hvo/${heilmittelverordnung}/edit`}>zur Bearbeitung</Link>)&nbsp;
              </>
            )}
            | <PatientText patient={patient} showGender /> (
            <Link to={`/${serverHash}/contacts/customers/${patient && patient.id}`}>zur Patientenkartei</Link>)
          </span>
        }
      />
      <div style={{ display: 'flex' }}>
        {/* left panel */}
        <div className="leftColumn">
          <NewHVOForm customer={patient} hvoId={hvo && hvo.id} initialValues={hvo} setHVO={setHVO} viewOnly />
          <FileUploadWithLightbox
            isHVOFormChanged={isHVOFormChanged}
            {...dateien}
            value={hvo.dateien || dateien.value}
            assignTo={{
              id: hvo ? hvo.id : null,
              entity: 'heilmittelverordnungen',
            }}
            style={{ paddingBottom: '15px' }}
            onFinish={() => {
              setTimeout(handleSubmit(initializeForm.bind(this)), 0)
            }}
            readOnly={isReadOnly()}
          />
          <Button
            style={{ margin: '10px' }}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={pristine}
            onClick={() => {
              setTimeout(() => handleSubmit(_saveForm)(), 0)
            }}
          >
            Entfernen der Dateien speichern
          </Button>
        </div>
        <div className="rightColumn">
          <InputField
            disabled
            readOnly={true}
            label="IK des Leistungsbringers"
            value={praxisstammdaten?.institutionskennzeichen}
          />
          <div className="inline-fields">
            <CurrencyField
              disabled
              fullWidth
              label="Gesamt-Zuzahlung"
              value={hvoDaten?.copaymentAmount || 0}
              readOnly
              endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
            />
            <CurrencyField
              disabled
              fullWidth
              label="Gesamt-Brutto"
              value={hvoDaten?.grossAmount || 0}
              readOnly
              endAdornment={praxisstammdaten.currency === 'EUR' ? <>€</> : <>CHF</>}
            />
          </div>
          {hvoDaten?.positionsCount?.map((p) => (
            <div className="inline-fields">
              <InputField readOnly label="Heilmittel-Pos.-Nr." value={p.positionsnummer} />
              <InputField readOnly label="Faktor" value={p.faktor} />
            </div>
          ))}
          <DatesListView
            dialogActions={dialogActions}
            filter={{ hvo: hvo?.id }}
            columns={['datum']}
            customCols={[
              {
                header: 'Leistung',
                data: (termin) => {
                  let duration = differenceInMinutes(termin.ende, termin.beginn)
                  let terminLeistung = {}
                  const terminLeistungen = termin.leistungen.filter((leistung) => leistung.istKKLeistung)
                  if (terminLeistungen.length === 1) {
                    terminLeistung = terminLeistungen[0]
                  }
                  const leistungenArray = Object.keys(leistungenKK).map((k) => leistungenKK[k])

                  if (heilmittelverordnungen[heilmittelverordnung]?.nagelkorrektur) {
                    if (terminLeistungen.length > 1) {
                      // concatenate leistung.beschreibung for all leistungen
                      let result = ''
                      terminLeistungen.forEach((l, idx) => {
                        result +=
                          leistungenArray.find((leistung) => leistung?.positionsnummer === l.positionsnummer)
                            .beschreibung + (idx + 1 < terminLeistungen.length ? ', ' : '')
                      })
                      return result
                    }

                    return leistungenArray.find((leistung) => {
                      return leistung?.positionsnummer === terminLeistung.positionsnummer
                    }).beschreibung
                  } else {
                    if (termin.preparationNeeded) {
                      duration -= 15
                    }

                    return duration > 20 ? 'podologische Behandlung (groß)' : 'podologische Behandlung (klein)'
                  }
                },
              },
            ]}
            hvoView
          />

          <br />
          <InputField
            readOnly={true}
            label="Frequenzabweichung(en)"
            multiline
            rows={(begruendungenFrequenzueberschreitung?.length || 1) + 1}
            value={begruendungenFrequenzueberschreitung?.join('\n')}
          />
          {behandlungAbgebrochen.value && (
            <InputField
              readOnly={true}
              label="Behandlung abgebrochen am"
              value={
                (dateBefore && format(dateBefore.beginn, GERMAN_DATE_SHORT_YEAR_FNS)) ||
                format(hvo.ausstellungsdatum, GERMAN_DATE_SHORT_YEAR_FNS)
              }
            />
          )}
          <form className="root">
            <div style={{ paddingTop: '15px' }}>
              {!hvo?.gebuehrenbefreit && (
                <FormControlLabel
                  {...zuzahlungErhalten}
                  control={<Switch />}
                  label="Zuzahlung erhalten"
                  className="toggle"
                  onChange={_handleInputChange('zuzahlungErhalten')}
                  disabled={isReadOnly()}
                />
              )}
              <FormControlLabel
                {...behandlungAbgebrochen}
                control={<Switch />}
                label="Behandlung abgebrochen"
                className="toggle"
                onChange={_handleInputChange('behandlungAbgebrochen')}
                disabled={!hvoDaten?.inputCancelTreatmentActive || isReadOnly()}
              />
              <FormControlLabel
                {...abgerechnet}
                control={<Switch />}
                label="Verordnung abgerechnet"
                className="toggle"
                onChange={_handleInputChange('abgerechnet')}
                disabled={
                  !hvoDaten?.inputHVOSettledActive ||
                  isReadOnly() /* || (praxisstammdaten?.arniCustomer && hvo?.krankenkasse !== 1) */
                }
                /* disabled={hvo?.krankenkasse !== 1 && !heilmittelverordnungen[heilmittelverordnung]?.nagelkorrektur} */
              />
              {!!praxisstammdaten?.arniCustomer && (
                <Button
                  fullWidth
                  variant="contained"
                  disabled={
                    !hvoInterfaceIsIncludedInPlan ||
                    isReadOnly() ||
                    !isHvoAbrechenbar ||
                    (isHvoAbrechenbar?.prescription && isHvoAbrechenbar.prescription !== true) ||
                    (isHvoAbrechenbar?.patient && isHvoAbrechenbar.patient !== true) ||
                    (isHvoAbrechenbar?.doctor && isHvoAbrechenbar.doctor !== true) ||
                    (isHvoAbrechenbar?.masterData && isHvoAbrechenbar.masterData !== true) ||
                    !hvoComplete ||
                    (patient && patient.land !== 'DE')
                  }
                  onClick={() => _handleAbrechnenClick(hvoDaten)}
                  color="secondary"
                >
                  Daten an Abrechnungszentrum übermitteln
                  {/* Submit data to billing center */}
                </Button>
              )}
              {!!praxisstammdaten?.arniCustomer &&
                !!isHvoAbrechenbar &&
                !!hvoComplete &&
                patient?.land === 'DE' &&
                !hvoInterfaceIsIncludedInPlan && (
                  <Typography>
                    Ihr gewähltes Planmodell enthält keine Schnittstelle zur Krankenkassenabrechnung.{' '}
                    {currentUser?.authUserId === currentServer?.createdBy && (
                      <>
                        <MuiLink
                          target="_blank"
                          rel="noreferrer noopener"
                          href={
                            process.env.NODE_ENV === 'production'
                              ? 'https://pododesk.de/fuexc_unidy/start'
                              : 'https://staging.pododesk.de/fuexc_unidy/start'
                          }
                        >
                          Jetzt Planmodell upgraden
                        </MuiLink>
                        , um diese Funktion zu nutzen.
                      </>
                    )}
                  </Typography>
                )}

              {!!praxisstammdaten?.arniCustomer &&
                !!isHvoAbrechenbar?.prescription &&
                Array.isArray(isHvoAbrechenbar.prescription) && (
                  <div>
                    <span style={{ fontWeight: 'bolder', color: 'red' }}>Fehlende Felder in HVO:&nbsp;</span>
                    <span>{isHvoAbrechenbar.prescription.join(', ')}</span>
                  </div>
                )}
              {!!praxisstammdaten?.arniCustomer &&
                !!isHvoAbrechenbar?.patient &&
                Array.isArray(isHvoAbrechenbar.patient) && (
                  <div>
                    <span style={{ fontWeight: 'bolder', color: 'red' }}>
                      Fehlende Felder in Patientenkartei:&nbsp;
                    </span>
                    <span>{isHvoAbrechenbar.patient.join(', ')}</span>
                  </div>
                )}
              {!!praxisstammdaten?.arniCustomer &&
                !!isHvoAbrechenbar?.doctor &&
                Array.isArray(isHvoAbrechenbar.doctor) && (
                  <div>
                    <span style={{ fontWeight: 'bolder', color: 'red' }}>Fehlende Felder in Ärztekartei:&nbsp;</span>
                    <span>{isHvoAbrechenbar.doctor.join(', ')}</span>
                  </div>
                )}
              {!!praxisstammdaten?.arniCustomer &&
                !!isHvoAbrechenbar?.masterData &&
                Array.isArray(isHvoAbrechenbar.masterData) && (
                  <div>
                    <span style={{ fontWeight: 'bolder', color: 'red' }}>
                      Fehlende Felder in Praxisstammdaten:&nbsp;
                    </span>
                    <span>{isHvoAbrechenbar.masterData.join(', ')}</span>
                  </div>
                )}
              {!patient?.land && (
                <div>
                  <span style={{ fontWeight: 'bolder', color: 'red' }}>
                    Bitte tragen Sie in der Patientenkartei das Land des Patienten ein
                  </span>
                </div>
              )}
              {!!patient?.land && patient?.land !== 'DE' && (
                <div>
                  <span style={{ fontWeight: 'bolder', color: 'red' }}>
                    Es können nur Daten von Patienten mit Wohnsitz in Deutschland übermittelt werden
                  </span>
                </div>
              )}

              {!praxisstammdaten.arniCustomer && (
                <Button
                  fullWidth
                  variant="contained"
                  component="a"
                  href="https://hellmut-ruck.de/praxisverwaltung/wissenswertes/digitale-krankenkassenabrechnung-mit-arni/"
                  target="_blank"
                  rel="noreferrer"
                  color="secondary"
                  disabled={isReadOnly()}
                >
                  Wie kann ich via pododesk abrechnen?
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </StyledViewHVO>
  )
}

const mapStateToProps = (state) => ({
  bundeslaender: state.bundeslaender,
  krankenkassen: state.krankenkassen,
  leistungenKK: state.leistungenKK,
  verguetungKK: state.verguetungKK,
  customers: state.entities.patienten,
  heilmittelverordnungen: state.entities.heilmittelverordnungen,
  praxisstammdaten: praxisstammdatenSelector(state),
  state: state,
  termine: state.entities.termine,
  aerzte: state.entities.aerzte,
  currentUser: sCurrentUser(state),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    heilmittelverordnungen: bindActionCreators(hvoActions, dispatch),
  },
})

export default reduxForm(
  {
    form: 'hvoAbrechungForm',
    fields,
    destroyOnUnmount: true,
  },
  mapStateToProps,
  mapDispatchToProps,
)(memo(ViewHVO))
