import { ListItemIcon, MenuItem } from '@material-ui/core'
import { Eco as EcoIcon, LocalHospital as LocalHospitalIcon, Visibility as VisibilityIcon } from '@material-ui/icons'
import { FC } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { apiServerHashSelector } from '../../selectors/selectors'
import { fetchSecure, sApiServer, sCredentials } from '../../shared/utils/auth'
import { plan } from '../../shared/utils/constants'
import { getApiUrl } from '../../utils/auth'
import { isReadOnly } from '../../utils/helpers'
import { StyledListItemText } from './StyledListItemText'

interface Props {
  therapiebericht?: any // TODO: Fix it

  apiUrl?: string
  auth0Credentials?: string | null
  dateien?: any // TODO: Fix it

  onCloseFunc: any
}

const MenuItemsTherapiebericht: FC<Props> = ({
  therapiebericht,
  apiUrl,
  auth0Credentials,
  dateien,
  onCloseFunc,
  currentServer,
}: Props) => {
  const { serverHash } = useParams()
  const navigate = useNavigate()

  const isCalendarPlan =
    currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name === 'calendar'

  const downloadTherapieBericht = async (event, therapiebericht) => {
    event?.stopPropagation()
    const datei = dateien[therapiebericht.datei]
    const URL = `${apiUrl}/dateien/${datei.id}`
    const response = await fetchSecure(URL, { credentials: 'include' }, auth0Credentials)
    const blob = await response.blob()

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.style = 'display:none'
    link.download = datei.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    onCloseFunc()
  }

  const viewHVO = (hvoId) => {
    navigate(`/${serverHash}/hvo/${hvoId}`)
    onCloseFunc()
  }

  const newTherapieBericht = (patientId) => {
    navigate(`/${serverHash}/reports/add/${patientId}`)
    onCloseFunc()
  }

  if (!therapiebericht) {
    return null
  }

  return (
    <div>
      <MenuItem dense onClick={(event) => downloadTherapieBericht(event, therapiebericht)}>
        <ListItemIcon>
          <VisibilityIcon color="secondary" /> {/* ion-eye */}
        </ListItemIcon>
        <StyledListItemText primary="Bericht anzeigen" />
        {/* show report */}
      </MenuItem>

      {therapiebericht.heilmittelverordnung ? (
        <MenuItem dense onClick={() => viewHVO(therapiebericht.heilmittelverordnung)}>
          <ListItemIcon>
            <EcoIcon color="secondary" /> {/* ion-leaf */}
          </ListItemIcon>
          <StyledListItemText primary="HVO anzeigen" />
          {/* Show HVO */}
        </MenuItem>
      ) : null}
      {!isReadOnly() && !isCalendarPlan && (
        <MenuItem dense onClick={() => newTherapieBericht(therapiebericht.patient)}>
          <ListItemIcon>
            <LocalHospitalIcon color="secondary" /> {/* ion-medkit */}
          </ListItemIcon>
          <StyledListItemText primary="neuer Bericht" />
          {/* new report */}
        </MenuItem>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  apiUrl: getApiUrl(apiServerHashSelector(state)),
  auth0Credentials: sCredentials(),
  dateien: state.entities.dateien,
  currentServer: sApiServer(state),
})

export default connect(mapStateToProps, null)(MenuItemsTherapiebericht)
