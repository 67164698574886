import { Button, CircularProgress } from '@material-ui/core'
import { Assignment as AssignmentIcon } from '@material-ui/icons'
import { format } from 'date-fns'
import { cloneElement, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  change as changeFieldValue,
  destroy as destroyForm,
  getValues,
  initialize as initializeForm,
  reset as resetForm,
} from 'redux-form'
import isPristine from 'redux-form/lib/isPristine'
import * as anamnesisActions from '../../actions/anamnesis'
import { loadCustomer } from '../../actions/customers'
import { baseObject, fields } from '../../components/AnamnesisForm/utils'
import PatientText from '../../components/PatientText/PatientText'
import { GERMAN_DATE_TIME_FNS } from '../../constants/dateFormats'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import { usePrevious } from '../../utils/functions'
import { anamnesenDiesesPatientenSelector, ersterTerminDesPatientenHeuteOderVergangenheitSelector } from './selectors'

import { StyledAnamnesis } from './StyledAnamnesis'
import { sApiServer } from '../../shared/utils/auth'
import { isReadOnly } from '../../utils/helpers'
import { plan } from '../../shared/utils/constants'

// TODO: Check type and fix
interface Props {
  form: React.ReactNode | JSX.Element | any
  sidebar: React.ReactNode | JSX.Element | any
  patient: string
  serverHash: string
  location: any
  navigate: any

  patienten?: any
  actions?: any
  anamneseForm?: any
  anamnesenIds?: Array<any>
  anamnesen?: any
  initialLoadComplete?: boolean
  anamneseDarfAbgeschlossenWerden?: boolean
  anamnesenDiesesPatienten?: Array<any>
  dialogActions?: any
}

const Anamnesis: React.FC<Props> = ({
  anamnesen,
  anamneseForm,
  form,
  initialLoadComplete,
  patient: patientIdStr,
  patienten,
  sidebar,
  anamneseDarfAbgeschlossenWerden,
  anamnesenDiesesPatienten = [],
  serverHash,
  navigate,
  actions,
  dialogActions,
  currentServer,
}) => {
  const [pristine, setPristine] = useState<boolean>(true)
  const [anamnesenIndex, setAnamnesenIndex] = useState(0)

  let prevForm = usePrevious(form)
  let saveFormInterval = null
  let formRef = useRef<HTMLInputElement>(null)

  const isCalendarPlan = currentServer?.ownerPlanId && plan.find((p) => p.id === currentServer.ownerPlanId)?.name === 'calendar'

  useEffect(() => {
    // @ts-ignore
    if (location?.state?.anamnese) {
      // @ts-ignore
      const anamnese = location.state.anamnese
      const index = anamnesenDiesesPatienten?.indexOf(anamnese)
      index && setAnamnesenIndex(index)
      actions.form.initialize('anamneseForm', anamnese, fields)
    }
    // @ts-ignore
    saveFormInterval = setInterval(_triggerSaveForm, 30 * 1000)
    actions.loadCustomer(patientIdStr, true)

    return () => {
      if (saveFormInterval != null) {
        // @ts-ignore
        clearInterval(saveFormInterval)
      }
      const isDirty = _checkDirty()

      if (isDirty) {
        // @ts-ignore
        formRef?.current.submit()
      }
      actions.form.destroy('anamneseForm')
    }
  }, [])

  useEffect(() => {
    // if another sub-form is called save
    if (form !== prevForm) {
      _triggerSaveForm()
    }
    const dirty = _checkDirty()
    // if no medical history exists and the form has changed, create a new anamnesis
    if (anamneseForm.id && !anamneseForm.id.value && initialLoadComplete && pristine && dirty) {
      setPristine(false)
      // @ts-ignore
      setTimeout(formRef?.current.submit, 0)
    }
  }, [form, anamneseForm])

  const _saveForm = async (data) => {
    await actions.form.initialize('anamneseForm', data, fields)
    if (data.id) {
      await actions.anamnesen.updateAnamnese(data)
    } else {
      await actions.anamnesen.createAnamnese(data)
    }
  }

  const _triggerSaveForm = () => {
    if (isReadOnly() || isCalendarPlan) return

    const isDirty = _checkDirty()
    const form = formRef

    if (isDirty) {
      // @ts-ignore
      form.current.submit()
    }
  }

  const _checkDirty = () => {
    const pristine = Object.keys(anamneseForm)
      .filter((key) => typeof anamneseForm[key] === 'object')
      .every((key) => isPristine(anamneseForm[key].initial, anamneseForm[key].value))

    return !pristine
  }

  const _neueAnamneseAnlegen = async () => {
    const currentValues = getValues(anamneseForm)

    await actions.form.initialize(
      'anamneseForm',
      {
        ...currentValues,
        abgeschlossen: false,
        id: null,
      },
      fields,
    )
    // @ts-ignore
    await formRef.current.submit()

    navigate(`/${serverHash}/anamnesis/${currentValues.patient}/01`)
  }

  const patientId = parseInt(patientIdStr || '', 10)

  const submitting = anamneseForm._submitting

  // @ts-ignore
  const neuesteAnamneseId = Math.max(...anamnesenDiesesPatienten.map((anamnese) => anamnese.id))
  const neuesteAnamnese = anamnesen[neuesteAnamneseId] || undefined

  const currentValues = getValues(anamneseForm)
  const leereAnamnese = {
    ...baseObject,
    patient: patientId,
  }

  const initialValues = currentValues.patient === patientId ? undefined : neuesteAnamnese || leereAnamnese

  const patient = patienten[patientId]

  let erstellungsDatum = null
  if (currentValues.id) {
    erstellungsDatum = anamnesen[currentValues.id]?.createdAt
  }

  currentValues.id = currentValues.id || neuesteAnamneseId

  return (
    <StyledAnamnesis>
      <IconHeading
        icon={<AssignmentIcon />}
        text={
          <span>
            Anamnese & Befund | <PatientText patient={patient} showGender /> (
            <Link to={`/${serverHash}/contacts/customers/${patient && patient.id}`}>
              zur Patientenkartei {/* to the patient file */}
            </Link>
            )
          </span>
        }
      />
      <div className="root">
        <div className="form">
          {initialLoadComplete && form ? (
            cloneElement(form, {
              ref: formRef,
              onSubmit: (data) => _saveForm(data),
              initialValues: initialValues,
              readOnly: isReadOnly() || isCalendarPlan,
            })
          ) : (
            <CircularProgress className="circularProgress" size={100} thickness={7} />
          )}
        </div>
        <div className="sidebar">
          <Button
            variant="contained"
            color="secondary"
            className="openCloseDialogButton"
            style={{
              marginBottom: '15px',
            }}
            fullWidth
            startIcon={<AssignmentIcon />}
            onClick={() => {
              navigate(`/${serverHash}/documentation/${patientId}`)
            }}
          >
            Zur Dokumentation {/* For documentation */}
          </Button>
          <div className="row around-xs">
            <div className="col-xs-6">
              {anamnesenDiesesPatienten[anamnesenIndex + 1] && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    if (anamnesenIndex === 0 && !anamneseForm.abgeschlossen.value) {
                      _triggerSaveForm()
                    }
                    actions.form.initialize('anamneseForm', anamnesenDiesesPatienten[anamnesenIndex + 1], fields)
                    setAnamnesenIndex((prev) => prev + 1)
                  }}
                >
                  Ältere anzeigen {/* Show older */}
                </Button>
              )}
            </div>
            <div className="col-xs-6">
              {anamnesenDiesesPatienten[anamnesenIndex + 1] && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    actions.form.initialize('anamneseForm', anamnesenDiesesPatienten[anamnesenIndex - 1], fields)
                    setAnamnesenIndex((prev) => prev + 1)
                  }}
                >
                  Neuere anzeigen {/* Show newer ones */}
                </Button>
              )}
            </div>
          </div>
          {erstellungsDatum && (
            <span style={{ padding: '5px' }}>Erstellungsdatum: {format(erstellungsDatum, GERMAN_DATE_TIME_FNS)}</span>
          )}

          {sidebar}
          <Button
            variant="contained"
            color="secondary"
            className="openCloseDialogButton"
            disabled={
              !anamneseForm.id ||
              !anamneseForm.id.value ||
              (anamneseForm.abgeschlossen &&
                anamneseForm.abgeschlossen.value &&
                anamneseForm.abgeschlossen.value === true) ||
              !anamneseDarfAbgeschlossenWerden ||
              isReadOnly() || isCalendarPlan
            }
            onClick={() => dialogActions?.openFinishAnamneseDialog(currentValues)}
          >
            {
              anamneseForm.abgeschlossen && anamneseForm.abgeschlossen.value === true
                ? 'Abgeschlossen' // Completed
                : 'Abschließen' // to lock
            }
          </Button>
          {anamneseForm.abgeschlossen &&
            anamneseForm.abgeschlossen.value &&
            anamneseForm.abgeschlossen.value === true && (
              <Button
                variant="contained"
                color="primary"
                className="neueAnamneseButton"
                disabled={submitting || anamnesenIndex !== 0 || isReadOnly() || isCalendarPlan}
                onClick={_neueAnamneseAnlegen}
              >
                Neue Anamnese
              </Button>
            )}
          <span style={{ padding: '5px' }}>
            Ihre Eingaben werden automatisch als Entwurf zwischengespeichert.
            <br />
            Zum Abschließen der Anamnese bitte "ABSCHLIESSEN" klicken.
            {/* Your entries are saved automatically. */}
            <br />
            {!anamneseDarfAbgeschlossenWerden && (
              <span>Das Abschließen der Anamnese ist jedoch erst nach der ersten Behandlung möglich.</span>
              // However, it is only possible to complete the anamnesis after the first treatment.
            )}
          </span>
        </div>
      </div>
    </StyledAnamnesis>
  )
}

const mapStateToProps = (state, props) => ({
  initialLoadComplete: state.initialLoadComplete.patienten,
  anamneseForm: state.form.anamneseForm,
  anamnesen: state.entities.anamnesen,
  anamnesenIds: state.ids.anamnesen,
  patienten: state.entities.patienten,
  users: state.users,
  anamneseDarfAbgeschlossenWerden: ersterTerminDesPatientenHeuteOderVergangenheitSelector(state, props),
  anamnesenDiesesPatienten: anamnesenDiesesPatientenSelector(state, props),
  currentServer: sApiServer(state),
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loadCustomer: bindActionCreators(loadCustomer, dispatch),
    anamnesen: bindActionCreators(anamnesisActions, dispatch),
    form: bindActionCreators(
      {
        change: changeFieldValue,
        initialize: initializeForm,
        reset: resetForm,
        destroy: destroyForm,
      },
      dispatch,
    ),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Anamnesis)
