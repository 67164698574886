/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useParams } from 'react-router-dom'
import { reduxForm } from 'redux-form'

import CheckboxXOR from '../../../shared/components/CheckboxXOR'
import InputField from '../../../shared/components/InputField'

import DividerLink from '../components/DividerLink'
import { StyledAnamnesisForm } from '../styles'
import { fields } from '../utils'

const AF01 = ({ fields: { ik, ikHepatitis, ikHIV, ikMRSA, ikKommentar, patient }, readOnly }) => {
  const { serverHash } = useParams()

  return (
    <StyledAnamnesisForm autoComplete="off">
      <div className="outer">
        <div className="inner">
          <div className="columnFullWidth">
            <CheckboxXOR
              {...ik}
              className="checkboxXORHead"
              label="Infektionskrankheiten"
              labelNo="Nein"
              labelYes="Ja"
              disabled={readOnly}
            />
            {ik.value && (
              <>
                <CheckboxXOR
                  {...ikHepatitis}
                  className="checkboxXORHead"
                  label="Hepatitis"
                  labelNo="Nein"
                  labelYes="Ja"
                  disabled={readOnly}
                />
                <CheckboxXOR {...ikHIV} className="checkboxXORHead" label="HIV" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
                <CheckboxXOR {...ikMRSA} className="checkboxXORHead" label="MRSA" labelNo="Nein" labelYes="Ja" disabled={readOnly} />
              </>
            )}
            <InputField {...ikKommentar} multiline rows={5} label="Kommentar" readOnly={readOnly} />
            <DividerLink to={`/${serverHash}/anamnesis/${patient.value}/02`} iconDirection="down" />
          </div>
        </div>
      </div>
    </StyledAnamnesisForm>
  )
}

const validate = (values) => {
  const required = []

  const errors: KeyValue<string> = {}

  required.forEach((fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'Bitte ausfüllen.'
    }
  })

  return errors
}

export default reduxForm({
  form: 'anamneseForm',
  validate,
  fields,
  destroyOnUnmount: false,
})(AF01)
