import { Link, Typography } from '@material-ui/core'
import { EuroSymbol } from '@material-ui/icons'
import { useEffect, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as smsActions from '../../actions/sms'
import * as callActions from '../../actions/call'
import IconHeading from '../../shared/components/IconHeading/IconHeading'
import { plan } from '../../shared/utils/constants'
import { sCurrentUser } from '../../shared/utils/users'
import { PlanTable } from '../PlanTable/PlanTable'

const PlanSettings = ({ auth, currentUser, smsActions, callActions }): JSX.Element => {
  const [smsCount, setSmsCount] = useState({ practiceSmsCount: 0, totalSmsCount: 0 })
  const [callCount, setCallCount] = useState({ practiceCallCount: 0, totalCallCount: 0 })
  const planData = useMemo(() => {
    if (currentUser?.planId) {
      return plan.find((p) => p.id === currentUser.planId)
    }

    return null
  }, [currentUser?.planId])

  // Filter auth.apiServers by createdBy
  const apiServers = useMemo(() => {
    if (!auth?.apiServers?.length || !currentUser?.authUserId) {
      return []
    }

    return auth.apiServers.filter((server) => server.createdBy === currentUser.authUserId)
  }, [auth?.apiServers, currentUser?.authUserId])

  const loadReminderCount = async () => {
    const sms = await smsActions.getSmsCount()
    const call = await callActions.getCallCount()
    setSmsCount(sms)
    setCallCount(call)
  }

  useEffect(() => {
    loadReminderCount()
  }, [])

  return (
    <>
      <IconHeading icon={<EuroSymbol />} text="Ihr aktuelles Planmodell" />
      <div style={{ padding: 8 }}>
        {!planData && (
          <Typography variant="h6">
            Sie haben noch kein Planmodell.{' '}
            <Link
              href={
                process.env.NODE_ENV === 'production'
                  ? 'https://pododesk.de/fuexc_unidy/start'
                  : 'https://staging.pododesk.de/fuexc_unidy/start'
              }
              color="secondary"
            >
              Klicken Sie hier
            </Link>
            , um ein Planmodell auszuwählen.
          </Typography>
        )}
        {!!planData && (
          <>
            <PlanTable plan={planData} apiServers={apiServers} smsCount={smsCount} callCount={callCount} />
            <div style={{ marginTop: 16 }}>
              <Typography variant="h6">
                Sie möchten Ihr Planmodell upgraden oder kündigen?{' '}
                <Link
                  href={
                    process.env.NODE_ENV === 'production'
                      ? 'https://pododesk.de/fuexc_unidy/start'
                      : 'https://staging.pododesk.de/fuexc_unidy/start'
                  }
                  color="secondary"
                >
                  Hier klicken!
                </Link>{' '}
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  currentUser: sCurrentUser(state),
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => ({
  smsActions: bindActionCreators(smsActions, dispatch),
  callActions: bindActionCreators(callActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanSettings)
