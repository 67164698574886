import { TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab'
import lodash from 'lodash'
import { FC, useEffect, useState } from 'react'

interface Props {
  options?: Array<AutocompleteOption>
  onChange?: (value: any) => void
  initialValue?: any
  value?: any
  className?: string
  label?: string | JSX.Element
  fullWidth?: boolean
  error?: boolean
  helperText?: string | JSX.Element
  isLoading?: boolean

  InputProps?: any
  autoComplete?: string
  freeSolo?: boolean
  autoFocus?: boolean
  readOnly?: boolean
  onInputChange?: (value: string) => void
  placeholder?: string
  autoSelect?: boolean
  filterOptions?: any
  disabled?: boolean
  blurOnSelect?: boolean
}

// filter by filterText if exists else text
const defaultFilterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option: AutocompleteOption) => `${option.filterText || option.text}`,
})

const AutocompleteField: FC<Props> = ({
  options = [],
  onChange,
  initialValue,
  value,
  className = '',
  label,
  fullWidth = true,
  error,
  helperText,
  isLoading = false,

  InputProps = {},
  autoComplete = 'new-password',
  freeSolo,
  autoFocus,
  readOnly,
  onInputChange,
  placeholder = '',
  autoSelect = false,
  filterOptions = defaultFilterOptions,
  disabled,
  blurOnSelect = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption | null>(null)
  const [currentOptions, setCurrentOptions] = useState<Array<AutocompleteOption>>([])
  // set selectedOption based on value
  useEffect(() => {
    setCurrentOptions(options)
  }, [options])

  // set selectedOption based on value
  useEffect(() => {
    let newSelectedOption: AutocompleteOption | null = null
    if (value) {
      const option = currentOptions.find((option) => lodash.isEqual(option?.value, value)) || null
      if (option) {
        newSelectedOption = option
      } else {
        // for free solo strings - add new one item to options (if npt exist before)
        // for string free solo need also add autoSelect=true
        if (freeSolo && value != null && typeof value === 'string') {
          // autoSelect is used for string values
          const newOption: AutocompleteOption = { text: value, value }
          // newSelectedOption = newOption;
          const newOptions = [...currentOptions, newOption]
          // after that we trigger current effect again (currentOptions changed)
          setCurrentOptions(newOptions)
          return
        }
      }
    }
    setSelectedOption(newSelectedOption)
  }, [value, currentOptions])

  return (
    <Autocomplete
      loading={isLoading}
      loadingText="Lade Daten..."
      disabled={disabled}
      autoSelect={autoSelect}
      filterOptions={filterOptions}
      fullWidth={fullWidth}
      freeSolo={freeSolo}
      className={className}
      value={selectedOption}
      options={currentOptions}
      getOptionLabel={
        // for freeSolo option can be 'string'
        (option: AutocompleteOption | string) => String((option as AutocompleteOption)?.text || option || '')
      }
      getOptionSelected={(option: AutocompleteOption, item: AutocompleteOption) => {
        return lodash.isEqual(option?.value, item?.value)
      }}
      getOptionDisabled={(option) => {
        return !!option.unselectable //&& !!option.undeleteable
      }}
      onChange={(_, selectedValue: string | AutocompleteOption | null) => {
        const value = (selectedValue as AutocompleteOption)?.value ?? selectedValue ?? null
        onChange && onChange(value)
      }}
      onInputChange={(_, value: string) => (onInputChange && onInputChange(value)) || null}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          variant="standard"
          label={label}
          error={error}
          helperText={helperText}
          autoFocus={autoFocus}
          InputProps={{
            ...InputProps,
            ...params.InputProps,
            autoComplete,
            readOnly,
          }}
          placeholder={placeholder}
        />
      )}
      blurOnSelect={blurOnSelect}
    />
  )
}

export default AutocompleteField
